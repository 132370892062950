import { Suspense, useEffect, useRef } from "react";
import "./App.css";

import {
  DataDeletion,
  FlashScreen,
  Introduce,
  GamePortal,
  MainLayout,
  MemberPrizeHistories,
  PointTransfer,
  PolicyPrivacy,
  TermCondition,
  ZaloAuth,
  PaymentGateway,
} from "@/pages";
import useAuthStore from "@/store/auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "./shared-components/spinner";

const defaultOptions = {
  queries: {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  },
};
const queryClient = new QueryClient({ defaultOptions });

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="flashscreen" />,
      },
      {
        path: "/flashscreen",
        element: <FlashScreen />,
      },
      {
        path: "/term-condition",
        element: <TermCondition />,
      },
      {
        path: "/policy-privacy",
        element: <PolicyPrivacy />,
      },
      {
        path: "/data-deletion",
        element: <DataDeletion />,
      },
      {
        path: "/zalo",
        element: <ZaloAuth />,
      },
      {
        path: "/payment",
        element: <PaymentGateway />,
      },
      {
        path: "/game",
        children: [
          {
            path: ":id",
            element: <Introduce />,
          },
          {
            path: ":id/prize-histories",
            element: <MemberPrizeHistories />,
          },
          {
            path: ":id/detail",
            element: <GamePortal />,
          },
          {
            path: ":id/point-transfer",
            element: <PointTransfer />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <p>Not found</p>,
  },
]);

function App() {
  let appMsgRef = useRef();
  const setToken = useAuthStore((state) => state.setToken);
  const token = useAuthStore((state) => state.token);

  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("MICROSITE_LOADED");
    }
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    const getAppMessage = (e) => {
      if (e.data !== "BACK_COMMAND") {
        let { TOKEN: _TOKEN } = JSON.parse(e.data);
        localStorage.setItem("_TOKEN", _TOKEN);
        if (token !== _TOKEN) setToken(_TOKEN);
      }
    };
    if (!iOS) {
      //For Android
      appMsgRef.current = document.addEventListener(
        "message",
        getAppMessage,
        false
      );
    } else {
      //For iOS
      appMsgRef.current = window.addEventListener(
        "message",
        getAppMessage,
        false
      );
    }
    return () => {
      document.removeEventListener("message", getAppMessage);
      window.removeEventListener("message", getAppMessage);
    };
  }, []);

  return (
    <Suspense
      fallback={
        <div className="my-4">
          <Spinner />
        </div>
      }
    >
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ToastContainer position="top-center" hideProgressBar theme="colored" />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Suspense>
  );
}

export default App;
