// import MainLayout from "./main-layout";
// import FlashScreen from "./flash-screen";
// import Introduce from "./introduce";
// import LuckyWheel from "./lucky-wheel";
// import PointTransfer from "./point-transfer";

import { lazy } from "react";

const MainLayout = lazy(() => import("@/pages/main-layout"));
const FlashScreen = lazy(() => import("@/pages/flash-screen"));
const Introduce = lazy(() => import("@/pages/introduce"));
const GamePortal = lazy(() => import("@/pages/games"));
const PointTransfer = lazy(() => import("@/pages/games/point-transfer"));
const MemberPrizeHistories = lazy(() => import("@/pages/prize-histories"));
const TermCondition = lazy(() => import("@/pages/term-condition"));
const PolicyPrivacy = lazy(() => import("@/pages/policy-privacy"));
const DataDeletion = lazy(() => import("@/pages/data-deletion"));
const ZaloAuth = lazy(() => import("@/pages/zalo"));
const PaymentGateway = lazy(() => import("@/pages/payment"));

export {
  FlashScreen,
  MainLayout,
  Introduce,
  GamePortal,
  PointTransfer,
  MemberPrizeHistories,
  TermCondition,
  PolicyPrivacy,
  DataDeletion,
  ZaloAuth,
  PaymentGateway,
};
